/* eslint-disable max-len */
import React from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { ActionButton, Footer, Layout, Navbar, PageHeader, Sponsor } from '@components';
import { site } from '@variables';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import '@scss/about-page.scss';

const AboutPage = () => <Layout className="about-page">
  <Navbar />
  <PageHeader
    title={site.organization.name}
    titleTransform="uppercase"
    subTextComponent={<p>{site.organization.tagline}</p>}
  />

  <Container style={{ marginBottom: `1rem`, marginTop: `3rem` }}>
    <Row className="justify-content-center mb-3">
      <a href="/about/info-request" target="_blank" rel="noopener noreferrer">
        <ActionButton>Questions About SoIT</ActionButton>
      </a>
      <a href="https://uceducation.az1.qualtrics.com/jfe/form/SV_eCJPcFn0DNLDHJY" target="_blank" rel="noopener noreferrer">
        <ActionButton style={{ width: `45rem` }}>Take our Survey</ActionButton>
      </a>
    </Row>

    <Row className="mb-4">
      <Col md={6}>
        <Link to="/about/undergrad">
          <StaticImage
            className="program-tile w-100 mb-4 mb-md-0"
            src="../../assets/images/soit/UndergradProgram.png"
            alt="Undergraduate Program"
          />
        </Link>
      </Col>
      <Col md={6}>
        <Link to="/about/grad">
          <StaticImage
            className="program-tile w-100"
            src="../../assets/images/soit/GradProgram.png"
            alt="Graduate Program"
          />
        </Link>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <Link to="/about/early-it">
          <StaticImage
            className="program-tile w-100 mb-4 mb-md-0"
            src="../../assets/images/soit/EarlyITProgram.png"
            alt="Early IT Program"
          />
        </Link>
      </Col>
      <Col md={6}>
        <Link to="/about/co-op">
          <StaticImage
            className="program-tile w-100"
            src="../../assets/images/soit/CoOpProgram.png"
            alt="Co-op Program"
          />
        </Link>
      </Col>
    </Row>
    <Row style={{ marginTop: `3rem` }}>
      <Col>
        <h2 style={{ marginBottom: `1rem`, textAlign: `center` }}>Presentation Schedule</h2>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Session Time</th>
              <th>Session Name</th>
              <th>Session Description</th>
              <th>Zoom Link</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>10:05 AM - 10:35 AM</td>
              <td>Early IT</td>
              <td>Do you have an interest in solving problems with technology? The Early IT program at the School of Information Technology is for you. Join this session and hear from Alana Calhoun, Assistant Director of Student Success, on how to get a jump start on your career and receive automatic admission to UC.</td>
              <td><a href="https://us02web.zoom.us/j/82609004385?pwd=QXhvUncrQVlKaERrcGhKQXIwUWZpQT09">Join this session now</a></td>
            </tr>
            <tr>
              <td>10:40 AM - 11:10 AM</td>
              <td>Co-Op</td>
              <td>Interested in learning about CoOp for IT majors here at the University of Cincinnati. Kenneth Pope, professor, and CoOp advisor will share information and details to answer all your questions in an interactive 30-minute live session.</td>
              <td><a href="https://us02web.zoom.us/j/82609004385?pwd=QXhvUncrQVlKaERrcGhKQXIwUWZpQT09">Join this session now</a></td>
            </tr>
            <tr>
              <td>11:15 AM - 11:55 AM</td>
              <td>SoIT Programs</td>
              <td>Information Technology is one of the fastest-growing disciplines in higher education. We live in an electronic world, that relies on availability, secure systems to live, work, and play. IT professionals are needed in every industry. This presentation will focus on the Bachelor of Science in IT, Cybersecurity and Master of Science in IT including admissions requirements, application deadline dates and curriculum.</td>
              <td><a href="https://us02web.zoom.us/j/82609004385?pwd=QXhvUncrQVlKaERrcGhKQXIwUWZpQT09">Join this session now</a></td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  </Container>

  <Sponsor />
  <Footer />
</Layout>;

export default AboutPage;
